import { useEffect, useState } from 'react';

import OdooDatabase from '../../data/odoo';
import TableList from '../TableListViewComponent';
import Loading from '../../components/LoadingComponent';

type RelatedWOsProperties = {
    asset_id: number;
}

type Descriptions = {
    key: string;
    label: string;
}

export default function RelatedWOs(props: RelatedWOsProperties){
    const [woStateDescriptions, setWOStateDescriptions] = useState<Descriptions[]>([])
    const [quoteStateDescriptions, setQuoteStateDescriptions] = useState<Descriptions[]>([])

    async function getWOStatesDescriptions() {
          try {
            let list:Descriptions[] = []
            let odoo = OdooDatabase.getInstance();
            let result = await odoo.execute_kw('fieldservice.workorder','get_wo_state_names',[[]]);
            result.forEach( (element: any) => {
                list.push({key:element[0],label:element[1]})
            });

            setWOStateDescriptions(list);

          } catch (err: any) {
            console.log('Error', err);
          }
    }

    async function getQuoteStatesDescriptions() {
        try {
          let list:Descriptions[] = []
          let odoo = OdooDatabase.getInstance();
          let result = await odoo.execute_kw('fieldservice.workorder','get_quote_state_names',[[]]);
          result.forEach( (element: any) => {
              list.push({key:element[0],label:element[1]})
          });

          setQuoteStateDescriptions(list);

        } catch (err: any) {
          console.log('Error', err);
        }
  }

    useEffect(() => {
        getWOStatesDescriptions();
        getQuoteStatesDescriptions();
     }, [])

    const onRenderState = (field: string, data: any): JSX.Element => {
        const findItemDescription = (key: string, list: Descriptions[]) => {
            for(const item of list) {
                if (item.key === key) {
                    return item.label;
                }
            }
            return '';
        }
        let label = '';
        let quote_wo_type = '';
        if (data['quote_wo_type'] === 'quote') {
            quote_wo_type = 'QT';
            label = findItemDescription(data['qt_state'], quoteStateDescriptions);
        } else {
            quote_wo_type = 'WO';
            label = findItemDescription(data['state'], woStateDescriptions);
        }

        return (<span>{quote_wo_type}: {label}</span>)
    };

    return (

        <>
            {
                woStateDescriptions.length === 0 && quoteStateDescriptions.length === 0 ? (
                    <Loading loading={true} fullWidth={false}/>
                ) : (
                    <TableList
                        model="fieldservice.workorder"
                        fields={['name', 'type_as_char', 'date_completed', 'quote_wo_type',
                                 'description', 'due_date', 'state', 'qt_state']}
                        domain={[['asset_id','=', props.asset_id],
                                    '|',
                                    '&', ['quote_wo_type', '=', 'wo'],'!',['state','in',['draft','cancel']],
                                    ['quote_wo_type', '=', 'quote']
                                ]
                            }
                        elements={
                            [
                            {field: 'name', header: 'WO', link: '/wo/__id__'},
                            {field: 'type_as_char', header: 'Type'},
                            {field: 'due_date', header: 'Target Date', type: 'onlydate'},
                            {field: 'date_completed', header: 'Done', type: 'onlydate'},
                            {field: 'description', header: 'Description'},
                            {field: 'state', 'header': 'Status', onRender: onRenderState}
                            ]
                        }
                        pagination={true}
                        pageSize={5}
                        order="quote_wo_type, date_completed desc, id desc"
                    />
                )
            }
        </>

    )
}