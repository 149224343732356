import './css/workorders.css'

import * as BsIcons from "react-icons/bs"
import * as FaIcons from "react-icons/fa"
import * as MdIcons from "react-icons/md"

import React, { Component } from 'react'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ActionsRequired from './wosubcomponents/ActionsComponent'
import CompleteWODialog from './wosubcomponents/CompleteWODialog'
import AssetAssignments from './wosubcomponents/AssetAssignmentsComponent'
import AssetModalDialog from './AssetModalComponent'
import AttachmentsComponent from './wosubcomponents/AttachmentsComponent'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Button from '@material-ui/core/Button'
import CustomerModalDialog from './CustomerModalComponent'
import IconButton from '@material-ui/core/IconButton'
import Images from './wosubcomponents/ImagesComponent'
import { Link } from 'react-router-dom'
import Loading from './LoadingComponent'
import OdooDatabase from '../data/odoo'
import PartList from './wosubcomponents/PartListComponent'
import ProcedureList from './wosubcomponents/ProcListComponent'
import RelatedWOs from './wosubcomponents/RelatedWOsComponent'
import RequestedPO from './wosubcomponents/RequestPOComponent'
import SiteHistory from './wosubcomponents/SiteHistoryComponent'
import SelectEditComponent from './editors/SelectEditComponent'
import Signatures from './wosubcomponents/SignaturesComponent'
import SiteAsset from './wosubcomponents/SiteAssetsComponent'
import TasksHistory from './wosubcomponents/TasksHistoryComponent'
import TechAppDialog from './TechAppDialog'
import TechList from './wosubcomponents/TechListComponent'
import TextModalEdit from './editors/TextModalEditComponent'
import LocationEditor from './editors/LocationEditor'
import Typography from '@material-ui/core/Typography'
import {formatHTML} from '../data/utils'
import {updateDict} from '../data/utils'
import { useParams } from 'react-router-dom'
import TechTimeList from './wosubcomponents/TechTimeComponent'
import { IconContext } from 'react-icons';
import SpecialInstructionsDialog from './SpecialInstructionsDialogComponent';
import * as FiIcons from "react-icons/fi";

type WOParams = {
    wo_id: string;
}

type WOWarningsProperties = {
    workorder: any;
}

function WOWarnings(props: WOWarningsProperties){
    let workorder = props.workorder;
    let warnings = [];
    if(workorder.warnings.show_warnings){
        let i = 0
        warnings = workorder.warnings.warnings.map((warning: any) => {
            i++
            return (<li key={`warning_${i}`} className="wo_warning">{warning}</li>);
        });
    }
    if (workorder.warnings.show_warnings) {
        return (<div className="wo_warning_box"><ul>{warnings}</ul></div>);
    }
    else
        return (<></>)
}

type WOInfoProperties = {
    workorder: any;
    techs_note: string;
    wo_state_description: string;
    can_start_labor?: boolean;
    can_stop_labor?: boolean;
    can_start_travel?: boolean;
    can_start_travel_to_wo?: boolean;
    can_stop_travel?: boolean;
    can_start_diagnostic?: boolean;
    can_stop_diagnostic?: boolean;
    onTechNotesUpdate(wo_id: number, value: string): Promise<boolean>;
    onGateCodeUpdate(wo_id: number, value: string): Promise<boolean>;
    onManufacturerWarrantyRefNoUpdate(wo_id: number, value: string): Promise<boolean>;
    onCustomerClick(customer: any): void;
    onCustomerDivisionClick(customer: any): void;
    onWarrantyClick(warranty: any): void;
    onAssetClick(asset: any): void;
    onStartLabor(): void;
    onStartDiagnostic(): void;
    onStopLabor(): void;
    onStartTravel(): void;
    onStopTravel(): void;
    onCompleteWO(): void;
    onUpdateAssetState(recordId: number, value: any): Promise<boolean>;
    onUpdateCoordinates?(recordId: number, latitude: number, longitude: number): Promise<boolean>;
}

function WOInfo(props: WOInfoProperties){
    const has_labor_open = props.can_stop_labor || props.can_stop_diagnostic;
    if(props.workorder && props.workorder.has_all_info){
        let asset_address = '';
        let asset_states: { [id: string]: string; } = {'red': 'Red', 'green': 'Green', 'yellow': 'Yellow'};
        let asset_state = '';
        let workorder = props.workorder;
        let last_asset: any;
        let assets = workorder.assets_info.map((asset: any) => {
            let separator = last_asset ? (<span> / </span>) : (<></>);
            let asset_link = (<span key={`asset_link_container_${asset.id}`}>{separator}<Link key={`asset_link_${asset.id}`} to="#" onClick={() => {props.onAssetClick(asset)}}>{asset.name}</Link></span>);
            last_asset = asset;
            return asset_link;
        });
        if(last_asset){
            asset_address = last_asset.asset_address;
            asset_state = last_asset.asset_state;
            if(asset_state in asset_states){
                asset_state = asset_states[asset_state];
            }
        }
        return (
            <>
            <div className="data_block">
                <div className="data_item"><span className="data_label">Customer:</span><span className="data_data">
                    <Link to="#" onClick={() => {props.onCustomerClick(workorder.customer)}}>{workorder.customer_name}</Link>
                </span></div>
                <div className="data_item"><span className="data_label">Division:</span><span className="data_data">
                    <Link to="#" onClick={() => {props.onCustomerDivisionClick(workorder.customer_division)}}>{workorder.customer_division.name}</Link>
                </span></div>
                <div className="data_item"><span className="data_label">Asset:</span><span className="data_data">{assets}</span></div>
                {
                workorder.is_asset_in_warranty ?
                (<div className="data_item"><span className="data_label">Warranty:</span><span className="data_data">
                    <Link to="#" onClick={() => {props.onWarrantyClick(workorder.asset_warranty)}}> {workorder.asset_warranty.name}</Link>
                    </span></div>):
                (<></>)
                }
                <div className="data_item"><span className="data_label">Address:</span><span className="data_data">{asset_address}</span></div>
                <div className="data_item"><span className="data_label">Location:</span><span className="data_data">
                    <LocationEditor
                        latitude={workorder.asset_alat}
                        longitude={workorder.asset_along}
                        editable={true}
                        recordId={workorder.asset_id}
                        recordUpdate={props.onUpdateCoordinates}
                    />
                </span></div>
                <div className="data_item"><span className="data_label">Gate Code:</span><span className="data_data">
                    <TextModalEdit
                        dialogTitle={'Change Gate Code'}
                        recordId={workorder.wo_id}
                        initialValue={workorder.site_gate_code || ''}
                        recordUpdate={props.onGateCodeUpdate}
                        emptyString={''}
                        singleLine={true}
                        editIcon={true}
                    />
                </span></div>

                {
                workorder.site_tech.name ?
                (
                <>
                <div className="data_item"><span className="data_label">Site Tech:</span><span className="data_data">{workorder.site_tech.name}</span></div>
                <div className="data_item"><span className="data_label"></span><span className="data_data"><b>Phone: </b>{workorder.site_tech.phone} <b>Cell:</b> {workorder.site_tech.mobile}</span></div>
                </>
                ) :
                (<></>)
                }
                <div className="data_item"><span className="data_label">Asset State:</span>
                    <SelectEditComponent
                        recordId={workorder.asset_id}
                        value={last_asset.asset_state}
                        selectItems={[{value:'green', label: 'Green', className: 'asset_state_option_green'},
                            {value: 'red', label: 'Red', className: 'asset_state_option_red'},
                            {value: 'yellow', label: 'Yellow', className: 'asset_state_option_yellow'}]}
                        recordUpdate={props.onUpdateAssetState}
                        editable={has_labor_open}
                        disallowNull={true}
                        workingFullScreen={true}
                        alwaysEditMode={true}
                    />
                </div>
                <div className="data_item"><span className="data_label">WO Type:</span><span className="data_data">{workorder.wo_type}</span></div>
                <div className="data_item"><span className="data_label">State:</span><span className="data_data">{props.wo_state_description}</span></div>
                <div className="data_item"><span className="data_label">Substatus:</span><span className="data_data">{workorder.substatus}</span></div>
                <div className="data_item"><span className="data_label">Mfr. Warranty Ref.:</span><span className="data_data">
                    <TextModalEdit
                        dialogTitle={'Set Manufacturer Warranty Reference Number'}
                        recordId={workorder.wo_id}
                        initialValue={workorder.manufacturer_warrant_ref_no || ''}
                        recordUpdate={props.onManufacturerWarrantyRefNoUpdate}
                        emptyString={''}
                        singleLine={true}
                        editIcon={true}
                    />
                </span></div>
                <div className="data_item"><span className="data_label">Comments:</span><span className="data_data">{workorder.customer_description}</span></div>
                <div className="data_item"><span className="data_label">Tech's Notes:</span><span className="data_data">
                    <TextModalEdit
                        dialogTitle={'Edit Techs Notes'}
                        recordId={workorder.wo_id}
                        initialValue={props.techs_note ? props.techs_note : ''}
                        recordUpdate={props.onTechNotesUpdate}
                        emptyString={'Enter tech\'s notes'}
                        readonly={['complete', 'finalized','to_be_invoiced','invoiced','billing_exception'].includes(workorder.wo_state)}
                        singleLine={false}
                    />
                </span>
                </div>

            </div>
            <div className="wo_app_buttons">
            {
                props.can_start_travel_to_wo ? (
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<FaIcons.FaCarAlt/>}
                        onClick={props.onStartTravel}
                    >
                        Start Travel TO JOB
                    </Button>
                ) : (<></>)
            }
            {
                props.can_stop_travel ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<FaIcons.FaStopCircle/>}
                        onClick={props.onStopTravel}
                    >
                        Stop Travel
                    </Button>
                ) : (<></>)
            }
            {
            props.can_start_labor ? (<Button
                variant="contained"
                color="primary"
                startIcon={<FaIcons.FaWrench/>}
                onClick={props.onStartLabor}
            >
                Start Labor
            </Button>
            ) : (<></>)
            }
            {
            props.can_start_diagnostic ? (<Button
                variant="contained"
                color="primary"
                startIcon={<FaIcons.FaStethoscope/>}
                onClick={props.onStartDiagnostic}
            >
                Start Diagnostic
            </Button>
            ) : (<></>)
            }
            {
                props.can_stop_diagnostic  ? (<>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<FaIcons.FaStopCircle/>}
                    onClick={props.onStopLabor}
                >
                    Stop Diagnostic
                </Button>
                </>
                ) : (<></>)
            }
            {
                props.can_stop_labor ? (
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<FaIcons.FaStopCircle/>}
                    onClick={props.onStopLabor}
                >
                    Stop Labor
                </Button>
                ) : (<></>)
            }
            {
                props.can_stop_labor || props.can_stop_diagnostic ? (
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<BsIcons.BsFileEarmarkCheck/>}
                    onClick={props.onCompleteWO}
                >
                    Complete WO
                </Button>
                ) : (<></>)
            }
            </div>
            </>
        );
    }else{
        return (<></>);
    }
}

type WorkOrderComponentProperties = {
    workorder: any;
    wo_id: number;
    conf: any;
    current_user_id: number;
    can_start_labor: boolean;
    can_stop_labor: boolean;
    can_start_travel: boolean;
    can_start_travel_to_wo: boolean;
    can_stop_travel: boolean;
    can_start_diagnostic: boolean;
    can_stop_diagnostic: boolean;
    onMount(workorder_id: number): void;
    onTechStateChanged(workorder_id: number, workorder: any): void;
    onError(errorMsg: string, notModal?: boolean): void;
    onStartLabor(): void;
    onStartDiagnostic(): void;
    onStopLabor(): void;
    onStartTravel(): void;
    onStopTravel(): void;
}

class WorkOrderComponent extends Component<WorkOrderComponentProperties, any>{
    constructor(props: any){
        super(props);
        this.state = {
            loading: this.props.workorder == null,
            workorder: this.props.workorder,
            wo_state_description: this.props.workorder ? this.props.workorder.state_description : '',
            error: false,
            assetModalOpen: false,
            warrantyModalOpen: false,
            assetDialogId: 0,
            customerModalOpen: false,
            completeWODlgOpen: false,
            asset: false,
            customer: false,
            customerDialogTitle: '',
            techs_note: this.props.workorder ? this.props.workorder.tech_description : '',
            updating: false,
            wo_state: '',
            warranty_name: '',
            warranty_description: '',
            special_instructions_dlg_open: false
        }
        this.onTechNotesUpdate = this.onTechNotesUpdate.bind(this);
        this.onGateCodeUpdate = this.onGateCodeUpdate.bind(this);
        this.onManufacturerWarrantyRefNoUpdate = this.onManufacturerWarrantyRefNoUpdate.bind(this);
        this.refresh = this.refresh.bind(this);
        this.completeWO = this.completeWO.bind(this);
        this.updateAssetState = this.updateAssetState.bind(this);
        this.onUpdateCoordinates = this.onUpdateCoordinates.bind(this);
    }

    initState(){
        this.setState({
            loading: false,
            workorder: null,
            error: false,
            assetModalOpen: false,
            warrantyModalOpen: false,
            asset: false,
            customer: false,
            customerDialogTitle: '',
            techs_note: '',
        });
    }

    async completeWO(asset_state: string){
        console.log('asset state', asset_state);
        this.setState({updating: true});
        let odoo = OdooDatabase.getInstance();
        try{
            const result = await odoo.execute_kw('fieldservice.workorder', 'complete_wo', [[[this.props.wo_id], asset_state]])
            if(result){
                this.props.onTechStateChanged(this.props.wo_id,
                    {am_i_assigned_today_to_wo: this.state.workorder.am_i_assigned_today_to_wo,
                    state: this.state.workorder.wo_state})
                const wo = await odoo.read('fieldservice.workorder', [this.props.wo_id], ['state', 'state_description'])
                if(wo.length > 0){
                    this.setState({wo_state: wo[0].state, wo_state_description: wo[0].state_description})
                }
            }
            this.setState({updating: false});
            this.refresh();
        }catch(err: any){
            console.log('Error', err);
            this.setState({updating: false});
            this.props.onError(err.faultString)
        }
    }

    componentDidMount(){
        this.loadData();
        this.props.onMount(this.props.wo_id)
    }

    componentWillUnmount(){
        this.props.onMount(0)
    }

    refresh(){
        this.initState();
        setTimeout(() => {
            this.loadData();
            this.props.onTechStateChanged(this.props.wo_id, null)
        }, 0);

    }

    loadData(){
        if(!this.state.workorder){
            this.loadWO(this.props.wo_id);
        }else if(!this.state.workorder.has_all_info){
            this.loadExtraWOData(this.state.workorder);
        }
    }

    async loadExtraWOData(workorder: any){
        this.setState({loading: true, error: false});
        let odoo = OdooDatabase.getInstance();
        try{
            const wo = await odoo.execute_kw(
                'fieldservice.workorder',
                'get_wo_extra_data',
                [[workorder.wo_id]]
            );
            updateDict(workorder, wo);
            workorder.has_all_info = true;
            this.setState({loading: false, error: false, techs_note: wo.tech_description});
        }catch(err: any){
            console.log('Error', err);
            this.setState({loading: false, error: true});
        }
    }

    async loadWO(wo_id: number){
        this.setState({loading: true, error: false});
        let odoo = OdooDatabase.getInstance();
        try{
            const wo = await odoo.execute_kw(
                'fieldservice.workorder',
                'get_wos_by_app_filter',
                [[{wo_id: wo_id}, 0, 1]]);
            if(wo.length > 0){
                let workorder = wo[0];
                await this.loadExtraWOData(workorder);
                this.setState({workorder: workorder, loading: false,
                               wo_state: workorder.wo_state, wo_state_description: workorder.state_description});
            }
            else
                this.setState({loading: false, error: true});
        }
        catch(err: any){
            console.log('Error', err);
            this.setState({loading: false, error: true});
        }
    }

    async onTechNotesUpdate(wo_id: number, value: string): Promise<boolean>{
        let result = false;
        try{
            let odoo = OdooDatabase.getInstance();
            result = await odoo.update('fieldservice.workorder', wo_id, {
                tech_description: value
            });
        }
        catch(err: any){
            console.log('Error', err);
        }
        if(!result){
            this.props.onError("Error updating Tech's Notes", true)
        }
        return result;
    }

    async onGateCodeUpdate(wo_id: number, value: string): Promise<boolean>{
        let result = false;
        try{
            let odoo = OdooDatabase.getInstance();
            result = await odoo.execute_kw('fieldservice.workorder', 'update_gate_code', [[wo_id, value]]);
        }
        catch(err: any){
            console.log('Error', err);
        }
        if(!result){
            this.props.onError("Error updating Asset Gate Code", true)
        }
        return result;
    }

    async onUpdateCoordinates(asset_id: number, latitude: number, longitude: number): Promise<boolean> {
        let result = false;
        try{
            let odoo = OdooDatabase.getInstance();
            result = await odoo.execute_kw('fieldservice.asset', 'update_coordinates', [[asset_id, latitude, longitude]]);
            if(!result){
                this.props.onError("Error updating Site coordinates", false)
            }
        }
        catch(err: any){
            console.log('Error', err);
            this.props.onError(err.faultString, false);
        }

        return result;
    }

    async onManufacturerWarrantyRefNoUpdate(wo_id: number, value: string): Promise<boolean>{
        let result = false;
        try{
            let odoo = OdooDatabase.getInstance();
            result = await odoo.execute_kw('fieldservice.workorder', 'update_manufacturer_warranty_ref_no', [[wo_id, value]]);
        }
        catch(err: any){
            console.log('Error', err);
        }
        if(!result){
            this.props.onError("Error updating Manufacturer Warranty Reference Number", true)
        }
        return result;
    }

    closeModal(){
        this.setState({
            customerModalOpen: false,
            customer: false,
            assetModalOpen: false,
            warrantyModalOpen: false,
            asset: false,
            special_instructions_dlg_open: false
        });
    }

    openCustomerInfo(customer: any, title: string){
        this.setState({
            customerModalOpen: true,
            customer: customer,
            customerDialogTitle: title
        });
    }

    openWarrantyWindow(warranty: any){
        this.setState({
            warrantyModalOpen: true,
            warranty_name: warranty.name,
            warranty_description: warranty.description
        });
    }

    openAssetInfo(asset: any){
        this.setState({
            assetModalOpen: true,
            asset: asset,
            assetDialogId: 1 - this.state.assetDialogId, //Force remount
        });
    }

    openSpecialInstructionsDlg(){
        this.setState({
            special_instructions_dlg_open: true
        })
    }

    async updateAssetState(recordId: number, value: any): Promise<boolean>{
        let result = false;
        try{
            let odoo = OdooDatabase.getInstance();
            result = await odoo.execute_kw('fieldservice.asset', 'updateAssetState', [[recordId, value]]);
        }
        catch(err: any){
            console.log('Error', err);
        }
        if(!result){
            this.props.onError("Error updating asset state", true)
        }
        return result;
    }

    render(){
        let can_start_labor = this.props.can_start_labor;
        let can_stop_labor = this.props.can_stop_labor;
        let can_start_travel = this.props.can_start_travel;
        let can_stop_travel = this.props.can_stop_travel;
        let can_start_travel_to_wo = this.props.can_start_travel_to_wo;
        let can_start_diagnostic = this.props.can_start_diagnostic;
        let can_stop_diagnostic = this.props.can_stop_diagnostic;
        const has_time_open = can_stop_labor || can_stop_diagnostic;
        const has_labor_open = can_stop_labor;
        const has_diagnostic_open = can_stop_diagnostic;

        return (
            <div className="main_component_container">
                <Loading loading={this.state.updating}/>
                {
                    this.state.special_instructions_dlg_open ?
                    (<SpecialInstructionsDialog
                        open={true} workorder={this.state.workorder}
                        onClose={() => {this.closeModal()}}
                    />):(<></>)
                }
                <CustomerModalDialog open={this.state.customerModalOpen}
                    title={this.state.customerDialogTitle}
                    onClose={() => {this.closeModal()}}
                    customer={this.state.customer}
                />
                <AssetModalDialog
                    open={this.state.assetModalOpen}
                    asset={this.state.asset}
                    onClose={() => {this.closeModal()}}
                    key={`asset_dialog_${this.state.assetDialogId}`}
                />
                <TechAppDialog
                    open={this.state.warrantyModalOpen}
                    title={this.state.warranty_name}
                    onClose={() => {this.closeModal()}}
                >
                    <div dangerouslySetInnerHTML={{ __html: formatHTML(this.state.warranty_description) }} />
                </TechAppDialog>
                <CompleteWODialog
                    open={this.state.completeWODlgOpen}
                    workorder_id={this.state.workorder_id}
                    onOK={(asset_state: string) => {this.completeWO(asset_state); this.setState({completeWODlgOpen: false}) }}
                    onClose={()=>{this.setState({completeWODlgOpen: false})}}
                />
                <div className="componentheader wo_componentheader">
                <Breadcrumbs aria-label="breadcrumb" className="wo_breadcrumb">
                <Link to="/wos" color="inherit" href="/">
                    Work Orders
                </Link>
                <Typography color="textPrimary">{this.state.workorder ? this.state.workorder.name : 'WO'}</Typography>
                </Breadcrumbs>
                <IconButton onClick={this.refresh}><MdIcons.MdRefresh className="refresh_icon"/></IconButton>
                </div>
                {!this.state.loading && !this.state.error && this.state.workorder && this.state.workorder.warnings.show_warnings ?
                    (<Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<MdIcons.MdExpandMore/>}
                            className="wo_warnings_summary"
                        >
                            <Typography>WARNINGS</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="wo_warnings">
                            <WOWarnings workorder={this.state.workorder}></WOWarnings>
                        </AccordionDetails>
                    </Accordion>) : (<></>)
                }

                <Accordion defaultExpanded>
                    <AccordionSummary
                    expandIcon={<MdIcons.MdExpandMore/>}
                    aria-controls="wo_info_header"
                    id="wo_info_header"
                    className="wo_info_header"
                    >
                    <Typography>WO INFO</Typography>
                    {
                        (!this.state.error && !this.state.loading && this.state.workorder && this.state.workorder.has_description) ?
                        (<IconButton onClick={(e) => {e.stopPropagation(); this.openSpecialInstructionsDlg()}} className='special_instruction_icon'>
                            <IconContext.Provider value={{ color: 'green' }}>
                                <FiIcons.FiFileText/>
                            </IconContext.Provider>
                        </IconButton>): (<></>)
                    }
                    </AccordionSummary>
                    <AccordionDetails className="wo_info_container">
                    {this.state.loading ? (<Loading loading={this.state.loading} fullWidth={false}/>)
                      : this.state.error ?
                       (<div>Error loading WO</div>) :
                        (<WOInfo workorder={this.state.workorder}
                            wo_state_description={this.state.wo_state_description}
                            onAssetClick={(asset) => {this.openAssetInfo(asset)}}
                            onCustomerClick={(customer) => {this.openCustomerInfo(customer, customer.name)}}
                            onCustomerDivisionClick={(customer) => {this.openCustomerInfo(customer, 'Customer Division')}}
                            onWarrantyClick={(warranty) => {this.openWarrantyWindow(warranty)}}
                            techs_note={this.state.techs_note}
                            can_start_labor={can_start_labor}
                            can_stop_labor={can_stop_labor}
                            can_start_travel={can_start_travel}
                            can_stop_travel={can_stop_travel}
                            can_start_travel_to_wo={can_start_travel_to_wo}
                            can_start_diagnostic={can_start_diagnostic}
                            can_stop_diagnostic={can_stop_diagnostic}
                            onStartLabor={this.props.onStartLabor}
                            onStartDiagnostic={this.props.onStartDiagnostic}
                            onStopLabor={this.props.onStopLabor}
                            onStartTravel={this.props.onStartTravel}
                            onStopTravel={this.props.onStopTravel}
                            onCompleteWO={() => {this.setState({completeWODlgOpen: true})}}
                            onTechNotesUpdate={this.onTechNotesUpdate}
                            onGateCodeUpdate={this.onGateCodeUpdate}
                            onManufacturerWarrantyRefNoUpdate={this.onManufacturerWarrantyRefNoUpdate}
                            onUpdateAssetState={this.updateAssetState}
                            onUpdateCoordinates={this.onUpdateCoordinates}
                        />)
                    }
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary
                    expandIcon={<MdIcons.MdExpandMore/>}
                    aria-controls="procedures_header"
                    id="procedures_header"
                    >
                    <Typography>PROCEDURES</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                        this.state.workorder ? (
                            <ProcedureList workorder_id={this.state.workorder.wo_id} labor_open={has_time_open}
                                onUpdateError={this.props.onError}
                            />
                            ) : (<></>)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary
                    expandIcon={<MdIcons.MdExpandMore/>}
                    aria-controls="panel2a-content"
                    id="parts_headers"
                    >
                    <Typography>PARTS</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="parts_list_container">
                    {
                    this.state.workorder ? (
                        <PartList workorder_id={this.state.workorder.wo_id} labor_open={has_time_open}
                            wo_state={this.state.workorder.wo_state}
                            onError={this.props.onError}
                        />
                        ) : (<></>)
                    }
                    </AccordionDetails>
                </Accordion>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                    expandIcon={<MdIcons.MdExpandMore/>}
                    aria-controls="panel2a-content"
                    id="technicians_headers"
                    >
                    <Typography>TECHNICIANS</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    {
                    this.state.workorder ? (<TechList workorder_id={this.state.workorder.wo_id} labor_open={has_time_open}
                        onError={this.props.onError} wo_state={this.state.wo_state}
                        current_user_id={this.props.current_user_id}
                        ></TechList>
                    ):(<></>)
                    }
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                        expandIcon={<MdIcons.MdExpandMore/>}
                        aria-controls="panel2a-content"
                        id="tech_time_header"
                        >
                    <Typography>TRAVEL/LABOR/DIAGNOSTIC TIME</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    {
                        this.state.workorder ?
                        (<TechTimeList workorder_id={this.state.workorder.wo_id} workorder_name={this.state.workorder.name} onError={this.props.onError}/>):(<></>)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                        expandIcon={<MdIcons.MdExpandMore/>}
                        aria-controls="panel2a-content"
                        id="site_assets_header"
                    >
                        <Typography>ASSETS ON SITE</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                        this.state.workorder ?
                        (<SiteAsset workorder_id={this.state.workorder.wo_id}/>):(<></>)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                    expandIcon={<MdIcons.MdExpandMore/>}
                    aria-controls="panel2a-content"
                    id="asset_assignmens_headers"
                    >
                    <Typography>ASSETS ASSIGNED</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    {
                    this.state.workorder ? (<AssetAssignments workorder_id={this.state.workorder.wo_id}
                        onError={this.props.onError}
                    ></AssetAssignments>
                    ):(<></>)
                    }
                    </AccordionDetails>
                </Accordion>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                    expandIcon={<MdIcons.MdExpandMore/>}
                    aria-controls="panel2a-content"
                    id="actions_required_header"
                    >
                    <Typography>ACTIONS REQUIRED</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    {
                    this.state.workorder ? (<ActionsRequired workorder_id={this.state.workorder.wo_id}
                        onError={this.props.onError}
                        asset_id={this.state.workorder.asset_id}
                        category_id={this.state.workorder.asset_category_id}
                        wo_state={this.state.workorder.wo_state}
                    ></ActionsRequired>
                    ):(<></>)
                    }
                    </AccordionDetails>
                </Accordion>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<MdIcons.MdExpandMore/>}
                    aria-controls="panel2a-content"
                    id="requested_po_header"
                    >
                    <Typography>REQUEST POS</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                        this.state.workorder ?
                        (<RequestedPO workorder_id={this.state.workorder.wo_id} onError={this.props.onError}/>):(<></>)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                        expandIcon={<MdIcons.MdExpandMore/>}
                        aria-controls="panel2a-content"
                        id="related_wos_header"
                        >
                        <Typography>ASSET HISTORY</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                        this.state.workorder ?
                        (<RelatedWOs asset_id={this.state.workorder.asset_id}/>):(<></>)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                        expandIcon={<MdIcons.MdExpandMore/>}
                        aria-controls="panel2a-content"
                        id="site_history_header"
                        >
                        <Typography>SITE HISTORY</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                        this.state.workorder ?
                        (<SiteHistory asset_id={this.state.workorder.asset_id}/>):(<></>)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<MdIcons.MdExpandMore/>}
                    aria-controls="panel2a-content"
                    id="tasks_history_header"
                    >
                    <Typography>TASKS HISTORY</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                        this.state.workorder ?
                        (<TasksHistory asset_id={this.state.workorder.asset_id}/>):(<></>)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<MdIcons.MdExpandMore/>}
                    aria-controls="panel2a-content"
                    id="images_header"
                    >
                    <Typography>IMAGES</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                        this.state.workorder ?
                        (<Images workorder_id={this.state.workorder.wo_id} onError={this.props.onError}/>):(<></>)
                        }
                    </AccordionDetails>
                </Accordion>
                {
                (this.state.workorder && this.state.workorder.requires_signatures) ?
                (<Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                    expandIcon={<MdIcons.MdExpandMore/>}
                    aria-controls="panel2a-content"
                    id="signatures_header"
                    >
                    <Typography>SIGNATURES</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                        this.state.workorder ?
                        (<Signatures workorder_id={this.state.workorder.wo_id} onError={this.props.onError}
                            labor_open={has_labor_open}
                            diagnostic_open={has_diagnostic_open}
                            key={`WOSignatures_${has_labor_open}_${has_diagnostic_open}`}
                        />):(<></>)
                        }
                    </AccordionDetails>
                </Accordion>) : (<></>)
                }
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                        expandIcon={<MdIcons.MdExpandMore/>}
                        aria-controls="panel2a-content"
                        id="attachments_header"
                        >
                    <Typography>ATTACHMENTS</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                        this.state.workorder ?
                        (<AttachmentsComponent workorder_id={this.state.workorder.wo_id} onError={this.props.onError}/>):(<></>)
                        }
                    </AccordionDetails>
                </Accordion>
            </div>
        )
    }
}

type WorkOrderProperties = {
    conf: any;
    loading: boolean;
    current_user_id: number;
    can_start_labor: boolean;
    can_stop_labor: boolean;
    can_start_travel: boolean;
    can_start_travel_to_wo: boolean;
    can_stop_travel: boolean;
    can_start_diagnostic: boolean;
    can_stop_diagnostic: boolean;
    onMount(workorder_id: number): void;
    onTechStateChanged(workorder_id: number, workorder: any): void;
    onError(errorMsg: string, notModal?: boolean): void;
    onStartLabor(workorder_id: number, diagnostic?: boolean): void;
    onStopLabor(workorder_id: number): void;
    onStartTravel(workorder_id: number): void;
    onStopTravel(workorder_id: number): void;
}

export default function Workorder(props: WorkOrderProperties){
    let params = useParams<WOParams>();
    if(props.loading)
        return (<></>)
    let wo_id = parseInt(params.wo_id);
    var wo:any = null;
    return (
        <WorkOrderComponent
            key={`wo_${wo_id}`} workorder={wo} wo_id={wo_id} current_user_id={props.current_user_id} conf={props.conf}
            can_start_labor={props.can_start_labor}
            can_stop_labor={props.can_stop_labor}
            can_start_travel={props.can_start_travel}
            can_stop_travel={props.can_stop_travel}
            can_start_travel_to_wo={props.can_start_travel_to_wo}
            can_start_diagnostic={props.can_start_diagnostic}
            can_stop_diagnostic={props.can_stop_diagnostic}
            onTechStateChanged={props.onTechStateChanged}
            onMount={props.onMount}
            onError={props.onError}
            onStartLabor={()=>{props.onStartLabor(wo_id)}}
            onStartDiagnostic={()=>{props.onStartLabor(wo_id, true)}}
            onStopLabor={()=>{props.onStopLabor(wo_id)}}
            onStartTravel={()=>{props.onStartTravel(wo_id)}}
            onStopTravel={()=>{props.onStopTravel(wo_id)}}
        />
    )
}
